import {
  Box,
  Button,
  ChakraProps,
  colors,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
} from "@biblioteksentralen/react";
import { ChangeEventHandler, FormEventHandler, MutableRefObject, useRef } from "react";
import { X } from "react-feather";
import { DefaultContainer } from "../../../components/ContentContainer";
import { useOnClickLoad } from "../../../utils/hooks/useOnClickLoad";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { useUrlState } from "../../../utils/hooks/useUrlState";
import { useSitePalette } from "../../../utils/useSitePalette";
import { useSearchConfig } from "../../hooks/useSearchConfig";
import { searchParameterName } from "../../searchApi/constants";

// TODO: Should the submit button be a link, since it acts like one?

type SearchInputProps = {
  id: string;
  value: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  onSubmit: FormEventHandler;
  clear: () => void;
  label?: string;
  active?: boolean;
  autoFocus?: boolean;
  inputRef?: MutableRefObject<HTMLInputElement | null>;
} & ChakraProps;

export const SearchInput = ({
  id,
  onSubmit,
  value,
  onChange,
  clear,
  label,
  autoFocus = false,
  inputRef: inputRefFromProps,
  ...chakraProps
}: SearchInputProps) => {
  const { t } = useTranslation();
  const localInputRef = useRef<HTMLInputElement | null>(null);
  const inputRef = inputRefFromProps ?? localInputRef;
  const { isSearchIntegrationEnabled } = useSearchConfig();
  const paletteColor = useSitePalette().colors.search;
  const searchConfig = useSearchConfig();

  const handleClear: FormEventHandler = () => {
    clear();
    inputRef.current?.focus();
  };

  const [searchQuery] = useUrlState<string>("", searchParameterName);

  const { onClickLoad, isLoading } = useOnClickLoad(searchQuery === value);

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();
    if (!value || value === searchQuery) return;
    onClickLoad();
    onSubmit(event);
  };

  const placeholder = isSearchIntegrationEnabled ? t("Hva leter du etter?") : t("Søk i samlingen");

  return (
    <Box
      as="form"
      role="search"
      id={id}
      flexDirection="column"
      onSubmit={handleSubmit}
      width="100%"
      padding={{ base: "1rem 0", lg: "2rem 0" }}
      {...paletteColor.css}
      {...chakraProps}
    >
      <DefaultContainer>
        {searchConfig.searchIntegrationEnvironment === "staging" && (
          // eslint-disable-next-line i18next/no-literal-string
          <Text fontSize="xs" marginBottom="1rem">
            Merk: Søket på denne sida går mot staging/test-miljøet til BS Dataplattform. Hvis du ikke får noen treff kan
            det være fordi vi holder på å jobbe med noe der.
          </Text>
        )}
        <HStack
          position="relative"
          width="100%"
          maxWidth="container.lg"
          minWidth="8rem"
          alignItems="flex-end"
          spacing="1rem"
        >
          <InputGroup
            size="lg"
            // disable webkit styling in the input field to avoid border-radius looking strange with the dropdown
            // also disable default cancel cross icon in Chrome and otherWebkit browsers
            sx={{
              'input[type="search"]': { WebkitAppearance: "none" },
              'input[type="search"]::-webkit-search-cancel-button': { display: "none" },
            }}
          >
            <Input
              autoComplete="off"
              name="search"
              ref={inputRef}
              autoFocus={autoFocus}
              type="search"
              aria-label={placeholder}
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              _hover={{ backgroundColor: "rgba(0,0,0,.05)" }}
              transition=".25s"
              _placeholder={{ color: paletteColor.isBright ? colors.grey90 : colors.grey10 }}
              _focusVisible={{
                backgroundColor: "white",
                paddingLeft: "1rem",
                color: colors.grey90,
                _placeholder: { color: colors.grey90 },
              }}
              label={label ?? t("Søk")}
              hideLabel
              variant="flushed"
              backgroundColor="transparent"
              borderBottom={`${paletteColor.isBright ? colors.grey90 : colors.grey10} .125rem solid`}
              paddingRight="3rem" // space for clear button
            />
            {isLoading && (
              <InputRightElement aria-hidden bottom="2px" top="unset">
                <Spinner size="sm" width="1.5rem" height="1.5rem" />
              </InputRightElement>
            )}
            {!isLoading && isSearchIntegrationEnabled && value && (
              <InputRightElement
                as="button"
                type="button"
                onClick={handleClear}
                aria-label={t("Nullstill søk")}
                bottom="0"
                top="unset"
                cursor="pointer"
                _hover={{ backgroundColor: "rgba(0,0,0,.05)" }}
                transition="0.3s ease background"
              >
                <X size="1.5rem" />
              </InputRightElement>
            )}
          </InputGroup>
          <Button type="submit" variant="secondary" size="lg" paddingX={{ md: "2.5rem" }}>
            {t("Søk")}
          </Button>
        </HStack>
      </DefaultContainer>
    </Box>
  );
};
