import { Box, Flex, Grid, Stack, Text } from "@biblioteksentralen/react";
import { useTranslation } from "../../../utils/hooks/useTranslation";
import { useSitePalette } from "../../../utils/useSitePalette";
import { HeaderFooterContainer } from "../../ContentContainer";
import SocialMediaView from "../../contactInfo/SocialMedia";
import { useCommonData } from "../CommonDataProvider";
import Coatofarms from "./CoatOfArms";
import { FooterMenu } from "./FooterMenu";
import LibryContentLinks from "./LibryContentLinks";
import { ResolvedSite } from "@libry-content/common";
import { usePathname } from "next/navigation";

export function Footer() {
  const { site } = useCommonData();
  const paletteColor = useSitePalette().colors.footer;
  const pathName = usePathname();

  return (
    <Grid
      key={pathName} // Fikser scroll-problem ved klikk på lenker i footer. Usikker på hvorfor, men en teori er at next har noe automatikk som går på at lenker skal oppføre seg om en slags innholdsfortegnelse om de fortsatt er i veiwport etter navigering, og at det da er flott med smooth-scroll. Men ikke vet jeg. Uansett: når vi endrer key vil footeren rendres på nytt, og next tror sansynligvis at lenken som ble klikket på har forsvunnet. 1 - 0 til oss.
      as="footer"
      gridTemplateRows="1fr auto auto"
      minHeight="20rem"
      {...paletteColor.css}
    >
      <Box>
        <HeaderFooterContainer>
          <Stack spacing="3rem" padding="2.5rem 0 2rem">
            <FooterMenu />
            <Coatofarms alignSelf="flex-end" padding=".75rem 0 .75rem .75rem" />
          </Stack>
        </HeaderFooterContainer>
      </Box>
      <Box background="rgba(0,0,0,.2)">
        <HeaderFooterContainer>
          <Flex justifyContent="space-between" alignItems="center" flexWrap="wrap" padding="1rem 0" gap="1rem">
            <Editor site={site} />
            <SocialMediaView socialMedia={site?.socialMedia} gap="1rem" marginLeft="auto" />
          </Flex>
        </HeaderFooterContainer>
      </Box>
      <LibryContentLinks />
    </Grid>
  );
}

const Editor = (props: { site?: ResolvedSite }) => {
  const { t } = useTranslation();

  if (!props.site?.editorName) return null;

  return (
    <Box>
      {t("Ansvarlig redaktør")}
      {": "}
      <Text as="span" translate="no">
        {props.site.editorName}
      </Text>
    </Box>
  );
};
