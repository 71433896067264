"use client";

import { Box, Grid } from "@biblioteksentralen/react";
import { ReactNode, useRef } from "react";
import SkipNavButton from "../../../../components/SkipNavButton";
import { CommonDataProvider } from "../../../../components/layout/CommonDataProvider";
import { CommonData } from "../../../../components/layout/commonData";
import { Footer } from "../../../../components/layout/footer/Footer";
import { Header } from "../../../../components/layout/header/Header";
import RedaktørPanel from "../../../../components/layout/header/RedaktørBanner";
import RediaPlatformProvider from "../../../../rediaPlatform/RediaPlatformProvider";
import { UseMultipleUrlStatesContextProvider } from "../../../../utils/hooks/useMultipleUrlStates/UseMultipleUrlStatesContextProvider";
import { useTranslation } from "../../../../utils/hooks/useTranslation";
import { PaletteProvider } from "../../../../utils/useSitePalette";
import { SanityPreviewLiveQuery } from "../../../sanityFetcher/SanityPreviewLiveQuery";
import { SanityDataWithPreviewConfig } from "../../../sanityFetcher/getAppFolderPageProps";

type Props = {
  sanityData: SanityDataWithPreviewConfig<CommonData>;
  children: ReactNode;
};

export const ClientSideSiteLayout = (props: Props) => (
  <SanityPreviewLiveQuery sanityData={props.sanityData}>
    {(data) => <CommonPageWrapper commonData={data}>{props.children}</CommonPageWrapper>}
  </SanityPreviewLiveQuery>
);

function CommonPageWrapper(props: { commonData: CommonData; children: ReactNode }) {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <CommonProviders commonData={props.commonData}>
      <Grid gridTemplateRows="auto auto 1fr auto" minHeight="100vh">
        <RedaktørPanel />
        <SkipNavButton title={t("Hopp til hovedinnhold")} focusOnClick={() => ref.current?.focus()} />
        <Header />
        <Box id="main" as="main" maxWidth="100vw" minHeight="40rem" tabIndex={-1} ref={ref}>
          {props.children}
        </Box>
        <Footer />
      </Grid>
    </CommonProviders>
  );
}

const CommonProviders = (props: { children: ReactNode; commonData: CommonData }) => (
  <CommonDataProvider commonData={props.commonData}>
    <PaletteProvider>
      <RediaPlatformProvider>
        <UseMultipleUrlStatesContextProvider>{props.children}</UseMultipleUrlStatesContextProvider>
      </RediaPlatformProvider>
    </PaletteProvider>
  </CommonDataProvider>
);
